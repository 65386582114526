  .brandPreviewContainer {
    min-width: 532px;
    max-width: 532px;
   /* width: 37vw;*/
    height: 44vh;
    background:#252852;
    border-radius: 43.7678px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 10px;
    min-height: 424px;
    margin-bottom: 60px;
  }

  @media screen and (min-width: 768px) {
    .brandPreviewContainer {
      margin-bottom: unset;
  }
  }

  .brandPreviewContainerOnTrial {
    composes: brandPreviewContainer;
    min-height: 434px;
  }

  .editYourBrandLink {
      composes: typography from './commonStyles.module.css';
      display: flex;
      justify-content: center;
      background: #13BCB4;
      cursor: pointer;
      border-radius: 37.2352px;
      width: fit-content;
      border: none;
      outline: none;
      margin-left: auto;
      margin-right: auto;
      font-weight: 600;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
      position: relative;
      top: 8%;
      padding: 0;
      min-height: 40px;
      width: 180px;
  }

  .editYourBrandLinkOnTrial {
    composes: editYourBrandLink;
    margin-top: 30px;
}

  .editYourBrandLinkText {
    position: relative;
    bottom: 1px;
  }

  .editIcon {
    position: relative;
    top: 3px;
    margin-right: 8px;
  }

  .previewBrandImage {
    display: grid;
    position: relative;
    width: 100%;
   /* height: 93%;*/
    padding: 0;
    margin: 0;
    bottom: 100px;
    padding-bottom: 20px;
    padding: 0;
    margin: 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .brandImageLink {
   /* width: 100%;
    height: 80%;*/
    margin-top: 25px;
    max-width: 425px;
    pointer-events: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    max-height: 230px;
  }
  .loadingText {
    composes: typography from './commonStyles.module.css';
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    position: relative;
    top: 12px;
  }

  .trialUpsell {
    display: block;
    margin-top: 15px;
  }

  .trialUpsellText {
    composes: typography from './commonStyles.module.css';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #117CDE;
  }

  .trialUpsellLink {
    background: #EA3989;
    border-radius: 37.2352px;
    padding: 0%;
    margin: 0;
    margin-top: 14px;
    min-width: 148px;
    height: 34px;
  }

  .trialUpsellVisibleLink {
    composes: trialUpsellLink;
    margin-right: 20px;
    min-width: 125px;
    position: relative;
    bottom: 5px;
  }

  .trialUpsellLinkText {
    composes: typography from './commonStyles.module.css';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    display: block;
    position: relative;
    top: 5px;
  }

  .trialUpsellVisibleLinkText {
    composes: trialUpsellLinkText;
    font-size: 12px;
  }

  .viewDemoLink{
   padding: 0;
   margin: 0;
   position: relative;
   bottom: 90px;
   display: flex;
   justify-content: center;
   margin-top: 30px;
   width: fit-content;
   margin-left: auto;
   margin-right: auto;
  }

  .viewDemoLinkInnerContainer {
    display: inline;
  }

  .viewDemoLinkText {
      composes: typography from './commonStyles.module.css';
      font-size: 14px;
      line-height: 40px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.3px;
      text-decoration-line: underline;
      color: #FFFFFF;
  }

  .upgradeMessageContainer {
    width: 71%;
    height: 11%;
    background: #32366D;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 25px;
    display: flex;
    justify-content: space-between;
  }

  .upgradeMessageText {
    composes: typography from './commonStyles.module.css';
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #FAFAFA;
    text-align: left;
    margin-left: 20px;
    position: relative;
    top: 5px;
  }

