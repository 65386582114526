.ldsHeart {
    display: block;
    position: relative;
    width: 160px;
    height: 160px;
    transform: rotate(45deg);
    margin-top: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) {
    .ldsHeart {
      text-align: unset;
      transform-origin: 40px 40px;
    }
  }

  .ldsHeartInner {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #13BCB4;
    animation: ldsHeart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .ldsHeartInner:after,
  .ldsHeartInner:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #13BCB4;
  }
  .ldsHeartInner:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
  }
  .ldsHeartInner:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
  }
  @keyframes ldsHeart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  }

  .ldsHeartInner13BCB4{
    composes: ldsHeartInner;
    background: #13BCB4;
  }
  .ldsHeartInner13BCB4:after,
  .ldsHeartInner13BCB4:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #13BCB4;
  }
  .ldsHeartInnerAE504B{
    composes: ldsHeartInner;
    background: #AE504B;
  }
  .ldsHeartInnerAE504B:after,
  .ldsHeartInnerAE504B:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #AE504B;
  }
  .ldsHeartInner439BC0{
    composes: ldsHeartInner;
    background: #439BC0;
  }
  .ldsHeartInner439BC0:after,
  .ldsHeartInner439BC0:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #439BC0;
  }
  .ldsHeartInner197E68{
    composes: ldsHeartInner;
    background: #197E68;
  }
  .ldsHeartInner197E68:after,
  .ldsHeartInner197E68:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #197E68;
  }
  .ldsHeartInnerAB7E3E{
    composes: ldsHeartInner;
    background: #AB7E3E;
  }
  .ldsHeartInnerAB7E3E:after,
  .ldsHeartInnerAB7E3E:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #AB7E3E;
  }
  .ldsHeartInner645DBB{
    composes: ldsHeartInner;
    background: #645DBB;
  }
  .ldsHeartInner645DBB:after,
  .ldsHeartInner645DBB:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #645DBB;
  }
  .ldsHeartInner323232{
    composes: ldsHeartInner;
    background: #323232;
  }
  .ldsHeartInner323232:after,
  .ldsHeartInner323232:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #323232;
  }
  