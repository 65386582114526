
.container {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  justify-items: center;
}

@media screen and (min-width: 768px) {
  .container {
    flex-wrap: nowrap;
  }
}

.homePageContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;
}

@media screen and (min-width: 768px) {
  .homePageContainer {
    flex-flow: row nowrap; /* required */
  }
}

.title {
  composes: typography from './commonStyles.module.css';
  font-weight: 700;
  justify-content: center;
  font-size: 16px;
  /*line-height: 54px;*/  
  text-align: center;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #FFFFFF;
  }

  @media screen and (min-width: 321px) {
    .title {
      font-size: 25px;
      letter-spacing: 6px;
    }
  }

  @media screen and (min-width: 768px) {
    .title {
      font-size: 50px;
    }
  }

  .desc {   
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
   /* line-height: 74px;*/
    text-align: center;
    color: #FFFFFF;
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    .desc {
      font-size: 34px;
      font-weight: bold;
      /* line-height: 74px;*/
    }
  }

  .homePageDescription {
    composes: desc;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /*max-width: 180px;*/
    margin-bottom: 10px;
  }

  @media screen and (min-width: 768px) {
    .homePageDescription {
      font-size: 34px;
      font-weight: 700;
      max-width: 602px;
     /* max-width: 302px;*/
      /* line-height: 74px;*/
    }
  }

  .img {
   /* flex-grow: 1;
    position: relative;
    top: 10.5vh;
    left: 3vw;*/
    display: none;
    max-width: 890px;
    max-height: 40vh;
    position: absolute;
    bottom: -185px;
    left: 55%;
    transform: translate(-50%);
    width: 60vw;
  }

  @media screen and (min-width: 768px) {
    .img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .oneLiner {
    margin: 0;
    margin-bottom: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    /*line-height: 40px;*/
    display: block;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 0.4px;
  }

  @media screen and (min-width: 768px) {
    .oneLiner {
      font-size: 28px;
      white-space: nowrap;
      /*margin-top: 22px;*/
    }
  }

  .mobileButton {
    display: none;
    padding: 9px 15px;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 2px;
    background-color: #fff;
    font-family: Ubuntu, Helvetica, sans-serif;
    color: #000;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    height: fit-content;
    margin-top: 20px;
  }
  
  .mobileButton {
    display: inline-block;
  }

  @media screen and (min-width: 768px) {
    .mobileButton {
      display: none;
    }
  }

  .header {
    display: flex;
    justify-items: flex-start;
    width: 986px;
    background-color: #FAFAFA;
    height: 30px;
    border-radius: 4px;
  }

  .poweredByWrapper {
    display: block;
    margin-top: 10px;
  }

  .poweredByText {
    composes: typography from './commonStyles.module.css';
    font-size: 23.7321px;
    letter-spacing: 0.444977px;
    color: #FFDDDB;
    margin-top: 20px;
    display: block;
    outline: none;
    border: none;
  }

  .linkNote {
    composes: typography from './commonStyles.module.css';
    font-size: 12.5px;
    line-height: 24px;
    display: -webkit-flex;
    display: block;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    color: #013348;
    margin: 8px 0px;
    margin-top: 25px;
  }

  .mainContentInnerWrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 768px) {
    .mainContentInnerWrapper {
      

  }
  }

  .customContentInnerWrapper {
    display: block;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .customContentInnerWrapper {
      margin-top: 58px; 
      box-sizing: border-box;
      width: 100%;
      flex: none;
      margin-left: -100%; /* required */
  }
  }

  .contentWrapperCustomPage {
    text-align: center;

  }

  @media screen and (min-width: 768px) {
    .contentWrapperCustomPage {
    position: relative;
    top: 120px;
    padding-left: 5rem;
  }
  }

  .mainContentOuterWrapper {
    display: block;
  }

  @media screen and (min-width: 768px) {
    .mainContentOuterWrapper {
      
  }
  }

  .formWrapper {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 768px) {
    .formWrapper {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex: none;
  }
  }

  .publishedTitle {
    composes: typography from './commonStyles.module.css';
    display: block;
    text-align: center;
    margin-top: 120px;
    font-size: 56px;
    /*line-height: 74px;*/
    letter-spacing: -0.244445px;
    color: #323232;
  }

  .startCustomizing {
    composes: typography from './commonStyles.module.css';
    display: flex;
    justify-content: center;
    background: #13BCB4;
    cursor: pointer;
    border-radius: 37.2352px;
    width: max-content;
    min-width: 212px;
    width: fit-content;
    height: 45px;
    border: none;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .startCustomizing:hover{
    background: #0F9790;
  }

  .addYourBrand {
    composes: typography from './commonStyles.module.css';
    cursor: pointer;
    color: #13BCB4;
    border: 2px solid #13BCB4;
    box-sizing: border-box;
    border-radius: 37.2352px;
    min-width: 204px;
    width: fit-content;
    height: 44px;
    padding-top: 7px;
    outline: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    text-decoration: none;
  }

  .coverImagePreview {
    position: absolute;
    z-index: -1;
    top: 30px;
    max-height: 23.8125vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .coveImageSite{
    position: fixed;
    top: 0;
    height: auto;
    max-height: 11vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  @media screen and (min-width: 768px) {
    .coveImageSite {
      top: 0;
      max-height: max(40vh, 350px);
      width: 100vw;
      z-index: 0;
  }
  }

  .CoverImg {
    width: 100%;
  }

  .EditableCoverImg {
    composes: CoverImg;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
  }

  .hiddenHoverableDiv {
    width: 100%;
    position: absolute;
    height: 42%;
    top: 25px;
    z-index: 1000;
    cursor: pointer;
  }

  .changeCoverImage {
    composes: typography from './commonStyles.module.css';
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    bottom: 13px;
    right: 22px;
    position: absolute;
    cursor: pointer;
    z-index: 4000;
  }

  .previewImageTitle {
    composes: typography from './commonStyles.module.css';
    font-weight: bold;
    font-size: 22.6686px;
    line-height: 40px;
    letter-spacing: 2.69864px;
    color: #FFFFFF;
  }

  .previewImageDescription {
    composes: typography from './commonStyles.module.css';
    font-weight: 600;
    font-size: 16.1919px;
    line-height: 40px;
    letter-spacing: -0.131934px;
    color: #FFFFFF;
  }

  .previewImageOneLiner {
    composes: typography from './commonStyles.module.css';
    font-size: 7.5562px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.161919px;
    color: #FFFFFF;
  }

  .ctaContainer {
    position: relative;
    display: none;
  }

  @media screen and (min-width: 768px) {
    .ctaContainer {
      display: unset;
    }
  }

  .ctaInnerContainer {
    min-width: 499px;
    max-width: 499px;
    /* width: 37vw; */
    height: 470px;
    background: #252852;
    border-radius: 43.7678px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 10px;
    /*min-height: 424px;
    max-height: 572px;*/
  }

  .ctaFeaturesList {
    display: block;
    position: relative;
    top: 28px;
  }

  .fetaureContainer {
    margin-top: 8px;
    margin-left: 10%;
    text-align: left;
    display: flex;
  }
  
  .featureText {
  composes: typography from './commonStyles.module.css';
  font-weight: 600;
  font-size: 16.8778px;
  line-height: 31px;
  letter-spacing: 0.511758px;
  color: #FFFFFF;
  }

  .separator {
    /*position: relative;
    top: 54px;*/
    margin-top: 54px;
    border: 0.01px solid #214666;
    height: 0;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .styledCtaLink {
    Width :242.73px;
    Height: 55px;
    background: #EA3989;
    border-radius: 50.7753px;
    text-align: center;
    outline: none;
   /* margin-top: 90px;*/
  }

  .styledCtaLinkText {
    composes: typography from './commonStyles.module.css';
    font-size: 24.5455px;
    line-height: 37px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.204545px;
    color: #FFFFFF;
    position: relative;
    bottom: 10px;
  }

  .ctaBadge {
    position: relative;
    left: 382px;
    bottom: 472px;
    z-index: 100;
  }

  .comingSoonContainer {

    height: 19px;
    background: rgba(19, 188, 180, 0.2);
    border-radius: 7.46667px;
    position: relative;
    top: 5px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .comingSoonText {
    composes: typography from './commonStyles.module.css';
    font-weight: 600;
    font-size: 11px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3962px;
    color: #13BCB4;
    position: relative;
    bottom: 2.4px;
  }
  
  .ctaEmailInput {
    composes: typography from './commonStyles.module.css';
    background: #30325A;
    border: 1.42296px solid #4B4E70;
    border-radius: 4px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    min-width: 260px;
    height: 42px;
    margin-top: 35px;
    margin-bottom: 26px;
    outline: none;
    color: white;
    font-size: 14px;
    line-height: 23px;
    padding-left: 14px;
  }

  .ctaInputContainer {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .ctaEmailInput::placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    /* or 163% */
    color:white;
    opacity: 0.8;
    margin-left: 0;
    padding: 0;
  }

  .cursorBlinking {
    width: 1px;
    line-height: 17px;
    background-color: white;
    font-size: 0px;
    position: absolute !important;
    animation: blink 1s linear infinite;
    top: 47px;
    right: 118px;
    z-index: 1000;
  }

  @keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
    }

