
.mockFormContainer {
width: 152px;
height: 246px;
background: #FFFFFF;
border: 1px solid #EBEBEB;
/*box-sizing: border-box;
box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);*/
display: block;
border-radius: 8px;
margin-left: 2vw;
}

.mockFormContainerBig {
  composes: mockFormContainer;
  width: 280px;
  height: 400px;
  }

.mockFileItemWrapper {
  display: inline-flex;
  }

.mockFileItem {
  display: block;
  padding-left: 16px;
  min-width: 92px;
  }

  .mockFileItemBig {
    composes: mockFileItem;
    margin-bottom: 6px;
    }

.mockFormFilesList {
  display: grid;
  margin-top: 5px;
}

.mockFormFilesListBig {
  display: grid;
  margin-top: 19px;
}

.mockFormDownloadAllButton {
  composes: typography from './commonStyles.module.css';
  height: 28.924705505371094px;
  width: 106.1171646118164px;
  border-radius: 37.2352px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  display: block;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  border: none;
  outline: none;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 20px;
}


.mockFormDownloadAllButtonBig {
  composes: mockFormDownloadAllButton;
  width: 165px;
  height: 45px;
  top: 75px;
  font-size: 18px;
}

.poweredByText {
  composes: typography from './commonStyles.module.css';
  font-size: 12px;
  letter-spacing: 0.444977px;
  color:white;
  display: block;
  outline: none;
  border: none;
}

.poweredByLink {
  composes: poweredByText;
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
}

.poweredByContainer{
  display: block;
  display: block;
  margin-top: 3vh;
  margin-left: 4vw;
}

.formWrapper{
  display: block;
  position: relative;
  top: 4vh;
 /* left: 2vw;*/
}

.formWrapperBig{
  composes: formWrapper;
  top: 27vh;
}

.mockFormTitlePlaceHolder {
  width: 107px;
  height: 7px;
  background: #C4C4C4;
  border-radius: 2px;
  margin-top: 21px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mockFormTitlePlaceHolderBig {
  composes: mockFormTitlePlaceHolder;
  width: 187px;
  height: 11px;
}

.mockFileItemNamePlaceHolder {
width: 41px;
height: 7px;
background: #C4C4C4;
border-radius: 2px;
margin-top: 19px;
display: block;
}

.mockFileItemNamePlaceHolderBig {
  composes: mockFileItemNamePlaceHolder;
  width: 82px;
  height: 11px;
  }

.mockFormFileSizePlaceHolder {
display: block;
margin-top: 5px;
width: 23px;
height: 7px;
background: #EBEBEB;
border-radius: 2px;
}

.mockFormFileSizePlaceHolderBig {
  composes: mockFormFileSizePlaceHolder;
  width: 50px;
  height: 11px;
  }

.downloadButton {
  position: relative;
  top: 14px;
  left: 35px;
}

.downloadButtonBig {
  position: relative;
  top: 12px;
  left: 140px;
}


